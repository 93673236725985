<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动类型"
                    prop="envelope_type">
        <el-radio-group v-model="form.envelope_type">
          <el-radio label="TEL">手机号码类型</el-radio>
          <el-radio label="YARD">抽奖码类型</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动总金额"
                    class="width300"
                    prop="max_money">
        <el-input type="number"
                  placeholder="活动总金额"
                  v-model="form.max_money"></el-input>
        <p class="input-tip">单位为元</p>
      </el-form-item>
      <el-form-item label="每用户累计可领取次数"
                    class="width300">
        <el-input type="number"
                  placeholder="每用户累计可领取次数"
                  v-model.number="form.receive_num"></el-input>
        <p class="input-tip">不填则不限制，请直接填数字</p>
      </el-form-item>
      <el-form-item label="标题栏色号"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入标题栏色号"
                  v-model="form.title_color"></el-input>
      </el-form-item>
      <el-form-item label="发放主体简称"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入发放主体简称"
                  v-model="form.subject"></el-input>
      </el-form-item>
      <el-form-item label="封面图片">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议640*1100像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <el-form-item label="发放主体LOGO">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议200*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="日期规则"
                    prop="started_at">
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item label="广告语"
                    prop="slogan"
                    class="width300">
        <el-input type="textarea"
                  placeholder="请输入广告语"
                  v-model="form.slogan"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import WxDate from '@/components/WxDate'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetPacket',
  data () {
    return {
      dateRule: {},
      imgList: [],
      imgList2: [],
      dateTime: [],
      areaAddress: {},
      form: {
        type: 'ENVELOPE',
        max_money: '',
        subject: '',
        title_color: '',
        title: '',
        started_at: '',
        ended_at: '',
        banner_img_id: '',
        img_id: '',
        imgData: {},
        activity_id: '',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        envelope_type: 'TEL',
        time_rule: 'ANY',
        time_rule_day: [],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7],
        slogan: '',
        receive_num: 1
      },
      rules: {
        subject: [
          { required: true, message: '请输入发放主体简称', trigger: 'blur' }
        ],
        participate_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请输入上传封面图片', trigger: 'change' }
        ],
        envelope_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        banner_img_id: [
          { required: true, message: '请输入上传背景图', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '选择活动日期', trigger: 'change' }
        ],
        list_province: [
          { required: true, message: '请选择区域，至少为省份', trigger: 'change' }
        ],
        appointment_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        mark_topic_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        mark_topic_msg: [
          { required: true, message: '请输入达标提示语', trigger: 'blur' }
        ],
        return_topic_num: [
          { required: true, message: '请输入重答次数', trigger: 'blur' }
        ]

      }
    }
  },
  computed: {
    totalMoney () {
      let total = 0
      this.money.map(item => {
        total += item.money * 100 * item.num
      })
      return (total / 100).toFixed(2)
    }
  },
  components: {
    Area,
    ImgUpload,
    WxDate
  },
  methods: {
    delMoney (index) {
      if (this.money.length > 1) {
        this.money.splice(index, 1)
      } else {
        this.$message.error('显示字段最少为一项')
      }
    },
    addMoney () {
      if (this.money.length < 8) {
        this.money.push({
          money: 1,
          num: 10
        })
      } else {
        this.$message.error('最多支持8个奖品')
      }
    },
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    addressChange (res) {
      this.form.allow_province = res.province
      this.form.allow_city = res.city
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!wxDateStatus) {
            return
          }
          const form = JSON.parse(JSON.stringify(this.form))
          form.max_money = form.max_money * 100
          const res = await setActivity(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('packet')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = activity.id
        activity.max_money = activity.max_money / 100
        activity.banner_img_id = getImgId(activity.banner_img_data)
        activity.banner_img_data && this.$set(this.imgList, 0, activity.banner_img_data)
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList2, 0, activity.img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
